/* eslint-disable global-require */
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { GatsbyImageData } from "../..";

export default () => {
  const data: GatsbyImageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "landing" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  `);
  const [
    collabImage,
    collabComputingImage,
    paperImage,
    wordsLogo
  ] = data.allFile.nodes.map((node) => node.childImageSharp.fluid);

  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh"
          }}
        >
          <div className="absolute top-0 w-full h-full bg-center bg-cover">
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black z-10"
            />
            <Img
              fluid={collabImage}
              className="absolute top-0 w-full h-full bg-center bg-cover z-0"
            />
          </div>
          <div className="container relative mx-auto z-20">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">PPoDS</h1>
                  <p className="mt-4 text-lg text-gray-300">
                    A new framework and web collaboration interface for
                    workflow-driven team science at scale.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-48 z-30 relative pt-1">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="las la-user-friends text-xl" />
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Test-Driven Development for Computational Data Science
                  Workflows
                </h3>
                <p className="text-md font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  As a methodology, PPoDS grew out of a need to enable
                  cross-disciplinary data science teams to start building a
                  workflow process in meetings. The process built in these
                  meetings is then used to agree upon measurable accountability
                  metrics for each iteration of the exploration and analytical
                  process development activity.
                </p>
                <p className="text-md font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  After experimenting with the PPoDS methodology, we observed a
                  lack of tools for measuring and testing the development of
                  each individual step in an analytical process towards
                  integration. We are currently developing the tools for
                  capturing, measuring, collecting and analyzing performance
                  metrics during exploratory workflow development and testing
                  process.
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-teal-600 z-20">
                  <Img
                    fluid={collabComputingImage}
                    alt="Collaboration"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-teal-600 fill-current"
                      />
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Bring Jupyter Notebooks Into Your Workflow Design
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      Combine Notebooks in a way that will scale out your
                      workflow to achieve better performance and allow
                      collaboration between team members.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="las la-users" />
                    </div>
                    <h6 className="text-xl font-semibold">Team Approach</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      The team develops an understanding of the approach to
                      solve a problem and creates a set of conceptual steps,
                      assigned to team members based on their expertise.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="las la-code" />
                    </div>
                    <h6 className="text-xl font-semibold">Code</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Each member separately develops the steps assigned to them
                      to pass the tests. When each member either reports success
                      or raised concerns to meet again, everyone creates reports
                      to explain their progress.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="las la-chart-line" />
                    </div>
                    <h6 className="text-xl font-semibold">Scale</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      The experimental Jupyter Notebooks come together as
                      everyone on the team finishes their section. Scaling up
                      the experiments will allow the team to run multiple
                      iterations of their code quickly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <Img
                  fluid={paperImage}
                  alt="Peapods Paper"
                  className="max-w-full rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="las la-book text-xl" />
                  </div>
                  <h3 className="text-3xl font-semibold">Publications</h3>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="las la-globe" />
                          </span>
                        </div>
                        <div>
                          <a
                            href="https://doi.ieeecomputersociety.org/10.1109/MCSE.2019.2919688"
                            className="text-gray-700 underline"
                          >
                            Toward a Methodology and Framework for
                            Workflow-Driven Team Science
                          </a>
                          <h5 className="text-gray-500">
                            IEEE Computing in Science & Engineering, 2019
                          </h5>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <p className="mt-4 text-sm leading-relaxed text-gray-600">
                    Scientific workflows are powerful tools for the management
                    of scalable experiments, often composed of complex tasks
                    running on distributed resources. Existing
                    cyberinfrastructure provides components that can be utilized
                    within repeatable workflows. However, data and computing
                    advances continuously change the way scientific workflows
                    get developed and executed, pushing the scientific activity
                    to be more data-driven, heterogeneous, and collaborative.
                    Workflow development today depends on the effective
                    collaboration and communication of a cross-disciplinary
                    team, not only with humans but also with analytical systems
                    and infrastructure. This paper presents a
                    collaboration-centered reference architecture to extend
                    workflow systems with dynamic, predictable, and programmable
                    interfaces to systems and infrastructure while bridging the
                    exploratory and scalable activities in the scientific
                    process. We present a conceptual design toward the
                    development of methodologies and services for effective
                    workflow-driven collaborations, namely the PPoDS methodology
                    for collaborative workflow development and the SmartFlows
                    Services for smart execution in a rapidly evolving cyber in
                    frastructure ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px", transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-24">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Build, test, and collaborate now.
                </h2>
                <button
                  type="button"
                  className="text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 bg-white"
                >
                  Sign up now
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer footerImg={wordsLogo} />
    </>
  );
};
