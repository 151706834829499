import React from "react";
import styled from "@emotion/styled";

import "../styles/global.css";
import "../styles/line-awesome.min.css";

const StyledLayoutRoot = styled.div`
  min-height: 100vh;
`;

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <>
    <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
  </>
);

export default LayoutRoot;
