import React, { useEffect, useState } from "react";
import firebase from "gatsby-plugin-firebase";

// Components
import { navigate } from "gatsby";
import Layout from "../components/LayoutRoot";
import Landing from "../components/landing/Landing";

export default () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(u => {
      if (!u) {
        setIsLoggedIn(false);
      } else {
        navigate("/lab");
      }
    });
  }, []);

  return (
    <Layout>
      <Landing />
    </Layout>
  );
};
